import React from 'react'
import { Header } from '../components/resuable/SapSolution'
import headerImage from '../assets/SAP/SAPHostingCover.png'
import { ContactContent } from '../components/resuable/SapComponents';
import { FaArrowCircleUp, FaChartBar, FaClipboardCheck, FaClock, FaCloud, FaCogs, FaGraduationCap, FaHeadset, FaLink, FaMoneyBillWave, FaSearch, FaServer, FaShieldAlt, FaWrench } from 'react-icons/fa';
import Footer from '../components/Footer';
import { MdCloudDone } from 'react-icons/md';
import { IoCloudOutline } from 'react-icons/io5';


function SAPHosting() {

    const InformationSystemOverview = () => {
        // const [fadeIn, setFadeIn] = useState(true);
        // console.log(cards[0].items.length, "cards");
        return (
            <div className={`relative z-10 mt-8 sm:mt-10 md:-mt-20 lg:-mt-32 xl:-mt-24 container mx-auto px-4 bg-[#49537780] rounded-lg mb-8 sm:mb-12 md:w-11/12 xl:w-10/12`}>
                <div className="lg:px-12 py-12 text-center ">
                    <p className="text-sm sm:text-base lg:text-lg">A huge number of enterprises are on SAP landscape today, and they are constantly looking for better and secure ways to manage their systems. Also, with the rapidly changing business needs, it has become an imperative to have options to scale up or down based on demand. Therefore, organizations today have strong driving factors in moving to the Cloud, which offers security, elasticity, improved performance, and all that at predictable costs. However, as most of the SAP applications are mission- critical for the businesses, the Cloud migrations have to be well planned and executed, so that there is near zero downtime, with no undesirable outcomes.</p>
                </div>
            </div>
        );
    }


    const certifications = [
        { title: 'SAP Certified', subtitle: 'in SAP HANA Operations Services' },
        { title: 'SAP Certified', subtitle: 'in Hosting Services' },
        { title: 'SAP Certified', subtitle: 'in Cloud Services' },
        { title: 'SAP Certified', subtitle: 'in DevOps' },
        { title: 'SAP Certified', subtitle: 'in Application Management Services' },
    ];

    const CertificationCard = ({ title, subtitle }) => (
        <div className="bg-[#51566E4D] bg-opacity-40 backdrop-blur-md p-4 sm:p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-white mb-2">{title}</h3>
            <p className="text-sm sm:text-base md:text-lg font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Certifications = () => (
        <div className="py-8 sm:py-12 2xl:py-24 flex flex-col items-center justify-center">
            {/* <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-white mb-8 md:mb-24">SAP Certified Provider</h2> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
                {certifications.map((cert, index) => (
                    <CertificationCard key={index} title={cert.title} subtitle={cert.subtitle} />
                ))}
            </div>
        </div>
    );



    const FeatureCard = ({ number, features }) => (
        <div className="bg-gray-900 rounded-lg p-6 border border-theme shadow-lg relative">
            <div className="absolute -top-4 -left-4 w-10 h-10 bg-custom-gradient rounded-full flex items-center justify-center text-white font-bold text-xl">
                {number}
            </div>
            <ul className="space-y-2 mt-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center text-white">
                        <span className="w-2 h-2 bg-theme rounded-full mr-3"></span>
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );

    const SAPHANAFeatures = () => {
        const features = [
            [
                "On-demand SAP HANA servers",
                "Immediate implementation",
                "E5-26xx processors with external SAN",
                "SAP-certified hardware/storage",
                "SAP-defined KPIs",
                "Hybrid cloud integration",
                "Faster internal storage",
                "Lower TCO for HANA T-shirt sizes up to 1.5 TB"
            ],
            [
                "Customer private cloud model",
                "On-demand SAP HANA servers",
                "Immediate implementation",
                "Compute and storage resources",
                "E5-26xx processors with external SAN",
                "SAP-certified hardware/storage",
                "SAP-defined KPIs",
                "Hybrid cloud integration",
                "Lower TCO for HANA T-shirt sizes up to 1.5 TB"
            ],
            [
                "Wide range of appliance sizes",
                "Pre-configured hardware set-up from leading OEMs",
                "Preinstalled software",
                "SAP-certified configurations",
                "Hybrid cloud integration",
                "Ideal for HANA size > 1.5 TB"
            ]
        ];

        return (
            <div className="bg-[#14192E80] p-8 py-12 2xl:py-24 flex justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl">
                    {features.map((featureSet, number) => (
                        <div key={number} className="bg-[#1A1F31] rounded-lg p-6 border border-theme shadow-lg relative">
                            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 w-8 h-8 bg-gradient-to-r from-[#4F75FF] to-[#A100FF] rounded-full flex items-center justify-center text-white font-bold text-lg">
                                {number + 1}
                            </div>
                            <ul className="space-y-2 mt-6">
                                {featureSet.map((feature, index) => (
                                    <li key={index} className="flex items-center text-white">
                                        <span className="w-2 h-2 bg-[#A100FF] rounded-full mr-3"></span>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const expertiseData = [
        { title: 'SAP Certified', subtitle: 'in multiple areas' },
        { title: 'Serving 180+', subtitle: 'Enterprise customers & SAP HANA industries on cloud' },
        { title: 'Delivering 99.99%', subtitle: 'availability & production uptime' },
        { title: 'Over 600B+', subtitle: 'SAP systems managed' },
        { title: '100+ of successful SAP', subtitle: 'migrations to the Cloud' },
        { title: 'Over 2000+ TB', subtitle: 'managed HANA' },
    ];

    const ExpertiseCard = ({ title, subtitle }) => (
        <div className="bg-opacity-10 bg-white p-4 sm:p-6 rounded-2xl shadow-md h-[154px] text-center flex flex-col justify-center">
            <h3 className="text-base sm:text-lg md:text-xl font-bold text-white mb-2">{title}</h3>
            <p className="text-xs sm:text-sm md:text-base font-medium text-gray-300">{subtitle}</p>
        </div>
    );

    const Expertise = () => (
        <div className="flex flex-col items-center justify-center mt-8 sm:mt-12 md:mt-24">
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-white mb-6 sm:mb-8 md:mb-12 text-center px-4">Our Expertise in handling SAP HANA workloads</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 max-w-6xl mx-auto">
                {expertiseData.map((item, index) => (
                    <ExpertiseCard key={index} title={item.title} subtitle={item.subtitle} />
                ))}
            </div>
        </div>
    );

    const MappingYourSuccess = () => {
        return (
            <div className="text-white flex items-center justify-center p-4 mt-8 sm:mt-12 xl:mt-24">
                <div className="w-11/12 2xl:w-10/12 mx-auto">
                    <div className="rounded-3xl p-4 sm:p-8 mb-8 sm:mb-12">
                        <h2 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center mb-4 sm:mb-8">
                            Mapping Your Success With DIAA CORP In Diverse Ways
                        </h2>
                        <p className="text-lg sm:text-xl md:text-2xl text-center mb-6 sm:mb-8 md:mb-12 text-gray-400">How SAP Hosting Services Can Bring Value</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 w-full max-w-5xl mx-auto">
                            {[
                                { title: "No upfront infrastructure investment", description: "Our customers will be able to shift from CapEx to OpEx model by leveraging our certified cloud infrastructure", Icon: IoCloudOutline },
                                { title: "Increased Performance", description: "Cloud4C offers a high-performance hosting environment, ensuring optimal performance for your SAP applications", Icon: FaChartBar },
                                { title: "Reliable Storage", description: "We provide highly available and redundant storage solutions to ensure data integrity and accessibility", Icon: MdCloudDone },
                                { title: "Scale up/down based on demand", description: "Easily scale your SAP infrastructure up or down based on your business needs, without any capital expenditure", Icon: FaArrowCircleUp },
                                { title: "Cost-effective", description: "Reduce your total cost of ownership with our pay-as-you-go model and optimized resource utilization", Icon: FaClipboardCheck },
                                { title: "Enhanced Security", description: "We offer advanced security measures to protect your SAP systems and data from threats", Icon: FaLink },
                                { title: "Pay-as-you-use", description: "Our flexible pricing model allows you to pay only for the resources you actually use", Icon: FaWrench },
                                { title: "Backup environment", description: "We provide comprehensive backup solutions to ensure your SAP data is always protected and recoverable", Icon: FaCloud },
                                { title: "Recovery Services", description: "Our disaster recovery services ensure business continuity in case of any unforeseen events", Icon: FaCogs },
                                { title: "High Availability", description: "We ensure 99.95% uptime for your SAP systems, minimizing disruptions to your business operations", Icon: FaServer },
                                { title: "Deployment in minutes", description: "Our automated provisioning allows for quick deployment of SAP environments", Icon: FaClock },
                                { title: "Predictable Maintenance costs", description: "With our managed services, you can predict and control your IT maintenance expenses", Icon: FaMoneyBillWave },
                                { title: "24/7 support and Single SLA", description: "We offer round-the-clock support with a single Service Level Agreement covering all aspects of your SAP hosting", Icon: FaHeadset },
                                { title: "Business Continuity", description: "Our robust infrastructure and disaster recovery solutions ensure your business operations continue without interruption", Icon: FaShieldAlt }
                            ].map((item, index) => (
                                <div key={index} className="relative group">
                                    <div className="absolute -inset-0.5 bg-custom-gradient rounded-3xl blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                                    <div className="relative bg-[#1C2237] rounded-3xl p-4 sm:p-8 flex flex-col items-center space-y-4 sm:space-y-6 h-full">
                                        <div className="w-16 h-16 sm:w-24 sm:h-24 rounded-full bg-custom-gradient flex items-center justify-center">
                                            <item.Icon className="text-white text-3xl sm:text-5xl" />
                                        </div>
                                        <span className="text-xs sm:text-sm font-medium text-center text-white">{item.title}</span>
                                        <p className="text-xs text-center text-gray-400">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="DIAA CORP SAP Hosting Services - Moving SAP to the Cloud"
                description="With our SAP Hosting Services,you can move your critical enterprise applications to the cloud, get increased availability, security & performance, and become resilient to evolving business requirements.
Connect with us"
                backgroundImage={headerImage}
                isExploreBtn={true}
            />
            <InformationSystemOverview />

            <div>
                <div className="px-4 md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 xl:mt-24">
                    <h2 className="text-xl sm:text-2xl font-semibold text-center mb-4">
                        DIAA CORP - Certified SAP Hosting partner
                    </h2>

                    <p className="text-sm sm:text-base leading-6 sm:leading-7">
                        We, at Cloud4C, are SAP Certified in SAP Hosting Services, and provide the right combination of technology, people and services required to host your SAP applications. When it comes to SAP Hosting Services, with our proven practices and methodologies, we can get your SAP applications up and running in no time. Being one of the leading SAP Hosting Providers, Cloud4C SAP experts understand your business requirements and work with you to build the SAP hosting strategy. Whether you need a Rated-4 Datacenter environment without investing huge amounts in IT hardware and SAP licenses or you are looking for fail-proof disaster recovery features, we have got you covered!
                    </p>

                </div>
            </div>

            <div className="mt-8 sm:mt-12 md:mt-24">
                <Certifications />
            </div>


            <div>
                <div className="px-4 md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 xl:mt-24">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        Ideal hosting partner for your SAP HANA ecosystem
                    </h2>

                    <p className="text-base leading-7">
                        Cloud4C helps you build, deploy, and support an SAP HANA environment that is aligned with your business requirements. As a SAP hosting partner, we provide multiple SAP-compliant hosting models, SAP implementation, and SAP Basis support.

                    </p>

                </div>
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <SAPHANAFeatures />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <ContactContent title='Write to us to know about various SAP HANA Deployment Models and Flexible Plans' />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <Expertise />
            </div>

            <div>
                <div className="md:w-11/12 2xl:w-10/12 text-white md:p-8 rounded-lg mx-auto text-center mt-8 sm:mt-12 md:mt-24 2xl:mt-32 px-4">
                    <h2 className="text-2xl font-semibold text-center mb-4">
                        Business benefits -- DIAA CORP SAP Hosting Services
                    </h2>

                    <p className="text-base leading-7">
                        We have 20 years of experience in colocation infrastructure, data center migration and security. Our scalable and dynamic SAP hosting services provide the perfect solutions with the capability to increase or decrease capacity as and when needed, and are designed to guarantee high availability and security of SAP systems. As part of our SAP Hosting Services, we take end-to-end responsibility of migrating and operating your SAP applications, letting your IT teams innovate and address crucial business requests.
                    </p>

                </div>
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <MappingYourSuccess />
            </div>

            <div className='mt-8 sm:mt-12 md:mt-24 2xl:mt-32'>
                <ContactContent title='Interested to host your SAP applications on the Cloud, with assured performance and availability?' />
            </div>

            <Footer />
        </div>

    )
}

export default SAPHosting