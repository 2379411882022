import { ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useRef, useState } from "react";

const StatCard = ({ value, description }) => (
    <div className="relative w-full h-auto sm:h-56 flex items-center justify-center">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#21213B] to-[#08082A] opacity-80 rounded-lg"></div>
        <div className="absolute top-0 left-0 w-16 h-16 bg-custom-gradient opacity-80 rounded-br-full"></div>
        <div className="relative z-10 p-6 w-full h-full flex flex-col justify-center items-center text-center">
            <p className="text-3xl sm:text-4xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    </div>
);

export const StatsGrid = ({ title, stats }) => {
    let gridClassName = "grid grid-cols-1 gap-6";
    
    if (stats.length === 2) {
        gridClassName = "grid grid-cols-1 sm:grid-cols-2 gap-6";
    } else if (stats.length === 3) {
        gridClassName = "grid grid-cols-1 md:grid-cols-3 gap-6";
    } else if (stats.length >= 4) {
        gridClassName = "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6";
    }

    return (
        <div className="text-white p-4 sm:p-8 flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-6 text-center">{title}</h2>
            <div className={`${gridClassName} w-full max-w-6xl`}>
                {stats.map((stat, index) => (
                    <div key={index} className="flex items-center justify-center">
                        <StatCard value={stat.value} description={stat.description} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const ReinventCard = ({ title, description, value, subDescription, hasButton }) => (
    <div className="bg-gradient-to-r from-[#21213B] to-[#08082A] rounded-lg p-6 flex flex-col justify-between h-full">
        {value ? (
            <>
                <p className="text-4xl font-bold mb-2">{value}</p>
                <p className="text-sm mb-4">{subDescription}</p>
                <h3 className="text-purple-500 text-sm mb-2">{title}</h3>
                <p className="text-xl font-semibold mb-4">{description}</p>
            </>
        ) : (
            <>
                <h3 className="text-purple-500 text-sm mb-2">{title}</h3>
                <p className="text-xl font-semibold mb-4">{description}</p>
            </>
        )}
        {hasButton && (
            <div className="mt-4 flex justify-end">
                <button className="w-8 h-8 rounded-full border border-theme flex items-center justify-center">
                    <ArrowRight className="w-4 h-4 text-theme" />
                </button>
            </div>
        )}
    </div>
);

export const ReinventGrid = ({ title, cards }) => {
    return (
        <div className="text-white p-8">
            <h1 className="text-3xl font-bold mb-8 text-center">{title}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {cards.map((card, index) => (
                    <ReinventCard key={index} {...card} />
                ))}
            </div>
        </div>
    );
};

const CarouselItem = ({ gradient, image, title }) => (
    <div className="w-72 sm:w-96 flex-shrink-0">
        <div
            className={`bg-gradient-to-br ${gradient} rounded-lg h-36 sm:h-48 pl-3 sm:pl-4 pt-2 relative overflow-hidden`}
            style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
            <div className="absolute inset-0 bg-black opacity-20"></div>
            <div className="relative z-10">
                <div className="bg-opacity-50 text-white pb-1 sm:pb-2 rounded inline-block text-xs font-light self-start">
                    RESEARCH REPORT
                </div>
                <h2 className="text-lg sm:text-xl font-bold">{title}</h2>
            </div>
        </div>
    </div>
);

const Carousel = ({ items, renderItem }) => {
    const [showButtons, setShowButtons] = useState(false);
    const carouselRef = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (carouselRef.current) {
                setShowButtons(carouselRef.current.scrollWidth > carouselRef.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            carouselRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="relative overflow-hidden w-full max-w-7xl mt-4 sm:mt-8">
            <div
                ref={carouselRef}
                className="flex space-x-2 sm:space-x-4 overflow-hidden"
            >
                {items.map((item, index) => renderItem(item, index))}
            </div>
            {showButtons && (
                <div className="flex justify-center mt-4 sm:mt-6 space-x-2 sm:space-x-4">
                    <button
                        onClick={() => scroll('left')}
                        className="bg-transparent border border-white rounded-full p-1 sm:p-2 md:hover:bg-white hover:bg-opacity-20 transition-colors"
                    >
                        <ChevronLeft className="text-white" size={20} />
                    </button>
                    <button
                        onClick={() => scroll('right')}
                        className="bg-transparent border border-white rounded-full p-1 sm:p-2 md:hover:bg-white hover:bg-opacity-20 transition-colors"
                    >
                        <ChevronRight className="text-white" size={20} />
                    </button>
                </div>
            )}
        </div>
    );
};

export const TrendingCarousel = ({ title, slides }) => {
    return (
        <div className="text-white p-4 sm:p-8 flex flex-col items-center">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">{title}</h1>
            <Carousel
                items={slides}
                renderItem={(slide, index) => (
                    <CarouselItem key={index} {...slide} />
                )}
            />
        </div>
    );
};

const AwardCard = ({ title, image, alt }) => (
    <div className="bg-navy-800 rounded-lg overflow-hidden flex-1 space-y-4 border-2 border-[#a100ff] border-opacity-40">
        <img
            src={image}
            alt={alt}
            className="w-full h-40 object-cover"
        />
        <div className="p-4">
            <h3 className="text-sm font-semibold mb-2">{title}</h3>
            <p className="text-xs text-gray-300">See more {'>'}</p>
        </div>
    </div>
);

const AwardsGrid = ({ awards }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-6xl">
        {awards.map((award, index) => (
            <AwardCard key={index} {...award} />
        ))}
    </div>
);

export const RecognitionSection = ({ title, awards }) => {
    return (
        <div className="text-white p-8 flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-6 md:mb-12">{title}</h2>
            <AwardsGrid awards={awards} />
        </div>
    );
};

const LeaderCard = ({ name, title, imageUrl, onSocialLinksClick }) => (
    <div className="rounded-lg p-4 flex flex-col items-center text-center">
        <img
            src={imageUrl || "/api/placeholder/120/120"}
            alt={name}
            className="w-32 h-40 object-cover rounded-lg mb-4"
        />
        <div>
            <h3 className="text-white text-lg font-semibold">{name}</h3>
            <p className="text-gray-400 text-sm max-w-64">{title}</p>
            <button
                className="mt-2 text-blue-400 hover:text-blue-300 text-sm"
                onClick={onSocialLinksClick}
            >
                Social links
            </button>
        </div>
    </div>
);

const Grid = ({ items, renderItem, className = "" }) => {
    const itemsPerRow = items.length === 4 || items.length === 6 ? 2 : 4;
    const gridClass = items.length === 1
        ? "flex justify-center"
        : `grid grid-cols-1 md:grid-cols-${itemsPerRow} gap-8 justify-items-center`;

    return (
        <div className={`${gridClass} ${className}`}>
            {items.map((item, index) => renderItem(item, index))}
        </div>
    );
};

export const LeaderGrid = ({ leaders, title = "Our leader" }) => {
    return (
        <div className="p-8 mx-auto">
            <h2 className="text-white text-3xl font-bold mb-8 text-center">{title}</h2>
            <Grid
                items={leaders}
                renderItem={(leader, index) => (
                    <LeaderCard
                        key={index}
                        name={leader.name}
                        title={leader.title}
                        imageUrl={leader.imageUrl}
                        onSocialLinksClick={() => console.log(`Show social links for ${leader.name}`)}
                    />
                )}
            />
        </div>
    );
};
