import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/CommunicationsAndMedia_cover.png'
import communications_img1 from '../../assets/Capabilities/communications_img1.png'
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Communications_img2 from '../../assets/Capabilities/communications_img2.png'
import Communications_img3 from '../../assets/Capabilities/communications_img3.png'
import Communications_img4 from '../../assets/Capabilities/communications_img4.png'
import communications_img5 from '../../assets/Capabilities/communications_img5.png'
import communications_img6 from '../../assets/Capabilities/communications_img6.png'
import communications_img7 from '../../assets/Capabilities/communications_img7.png'
import communications_img8 from '../../assets/Capabilities/communications_img8.png'
import Communications_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';


function CommunicationsAndMedia() {
    const CommunicationsAndMediaStats = () => {
        const stats = [
            { value: '$45B', description: 'The total addressable market for communications and media' },
            { value: '35%', description: 'of communications and media companies are investing in AI and machine learning to improve customer experience' },
            { value: '86%', description: 'of consumers would pay more for a better customer experience' },
            { value: '$1.7T', description: 'The value that 5G will add to the global economy by 2030' },
        ];

        return <StatsGrid title="Communications & media now" stats={stats} />;
    };


    const SegmentsWeSupport = () => {
        const [currentSlide, setCurrentSlide] = React.useState(0);
        const slides = [
            {
                category: "MEDIA",
                title: "Communications",
                description: "Communication providers enable mobile, scalable connectivity and information sharing between consumers, businesses and governments.",
                image: communications_img1,
            },
            {
                category: "ENTERTAINMENT",
                title: "Media & Entertainment",
                description: "Media and entertainment companies create, produce, and distribute content across various platforms to engage and entertain audiences.",
                image: communications_img1, // Using the same image for demonstration
            },
            {
                category: "TECHNOLOGY",
                title: "Digital Services",
                description: "Digital service providers offer innovative solutions and platforms that enhance connectivity, productivity, and user experiences.",
                image: communications_img1, // Using the same image for demonstration
            },
        ];

        const nextSlide = () => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        };

        const slideVariants = {
            hidden: { opacity: 0, x: 50 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: -50 }
        };

        return (
            <>
                <h2 className="text-2xl font-bold mb-6 xl:mb-12 text-center">Segments we support</h2>
                <div className="bg-[#14192E] text-white p-4 sm:p-8 rounded-2xl">
                    <div className="bg-navy-800 rounded-lg p-4 sm:p-6">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentSlide}
                                variants={slideVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.3 }}
                            >
                                <div className="flex items-center mb-4">
                                    <div className="w-3 h-3 bg-purple-500 rounded-full mr-2"></div>
                                    <span className="text-purple-500 font-semibold">{slides[currentSlide].category}</span>
                                </div>
                                <div className="flex flex-col sm:flex-row items-start">
                                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0 sm:mr-6">
                                        <div className="bg-purple-900 rounded-lg overflow-hidden">
                                            <img
                                                src={slides[currentSlide].image}
                                                alt={slides[currentSlide].title}
                                                className="w-full h-auto object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-xl font-semibold mb-2">{slides[currentSlide].title}</h3>
                                        <p className="text-gray-400 mb-4">
                                            {slides[currentSlide].description}
                                        </p>
                                        <div className="flex justify-end">
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}
                                                className="border-2 border-theme/50 rounded-full p-2"
                                            >
                                                <ChevronRight
                                                    className="text-theme cursor-pointer"
                                                    size={24}
                                                    onClick={nextSlide}
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </>
        );
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'New growth models',
                description: 'Revolutionize and monetize to offset innovation investments',
                hasButton: false,
            },
            {
                title: 'Customer experience',
                description: 'Empower your customers to get everything, everywhere, all at once',
                hasButton: true,
            },
            {
                title: 'Network transformation',
                description: 'Accelerate adoption and time-to-market across the network lifecycle',
                hasButton: false,
            },
            {
                title: 'Business, IT and operations',
                description: 'Build innovative business structure that transform core and differentiating capabilities',
                hasButton: false,
            },
            {
                title: 'Advertising & attention monetization',
                description: 'Attention is a precious commodity',
                hasButton: true,
            },
            {
                title: 'SMB Growth',
                description: 'Small to medium is big business',
                hasButton: true,
            },
            {
                title: 'Ecosystem strategy',
                description: 'Unlock value, separating assets, services and operations',
                hasButton: false,
            },
        ];




        return <ReinventGrid title="Reinvent with cloud" cards={cards} />;
    };
    const slides = [
        {
            title: "Reinvent for growth: Only the radical survive",
            image: Communications_img2,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "From survive to thrive: Tech transformation for CSPs' future",
            image: Communications_img3,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "Reinventing M&A with generative AI",
            image: Communications_img4,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];


    const CommunicationsMediaTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending in communications & media" slides={slides} />;
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "2024 Microsoft Media and Telco Partner of the Year",
                image: communications_img5,
                alt: "Microsoft Partner of the Year Award"
            },
            {
                title: "Databricks GSI partner of the year 2023, 4th year in a row",
                image: communications_img6,
                alt: "Databricks GSI Partner Award"
            },
            {
                title: "TM Forum 2023 Catalyst: AI & Innovation Excellence Award",
                image: communications_img7,
                alt: "TM Forum Catalyst Award"
            },
            {
                title: "Everest Group 5G Engineering Services PEAK Matrix® Assessment 2023",
                image: communications_img8,
                alt: "Everest Group 5G Services Assessment"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Communications_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Communications_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Communications_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Communications_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };


    return (
        <div className='bg-bg text-white'>
            <Header
                title="Be hyper-essential for hyper-connected Communications & Media"
                description="People and businesses are always on, whether watching, working, or enabling innovative new growth. Keep them engaged and successful by delivering the continuous experiences and capabilities they expect and need."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsAndMediaStats />
            </div>
            <div className='px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 max-w-7xl mx-auto'>
                <SegmentsWeSupport />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <ReinventWithCloud />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <CommunicationsMediaTrendingCarousel />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <GlobalRecognition />
            </div>
            <div className="px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16">
                <LeadershipSection />
            </div>
            <div className="pt-8 sm:pt-12 lg:pt-16">
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default CommunicationsAndMedia
