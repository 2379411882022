import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/Cloud_services_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Cloud_img1 from '../../assets/Capabilities/Cloud_img1.png'
import Cloud_img2 from '../../assets/Capabilities/Cloud_img2.png'
import Cloud_img3 from '../../assets/Capabilities/Cloud_img3.png'
import Cloud_partner_img1 from '../../assets/Capabilities/Cloud_partner_img1.png'
import Cloud_partner_img2 from '../../assets/Capabilities/Cloud_partner_img2.png'
import Cloud_partner_img3 from '../../assets/Capabilities/Cloud_partner_img3.png'
import Cloud_partner_img4 from '../../assets/Capabilities/Cloud_partner_img4.png'
import Cloud_awards_img1 from '../../assets/Capabilities/Cloud_awards_img1.png'
import Cloud_awards_img2 from '../../assets/Capabilities/Cloud_awards_img2.png'
import Cloud_awards_img3 from '../../assets/Capabilities/Cloud_awards_img3.png'
import Cloud_awards_img4 from '../../assets/Capabilities/Cloud_awards_img4.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
function CloudServices() {

    const CloudNowStats = () => {
        const stats = [
            { value: '86%', description: 'of companies reported an increase in their cloud initiatives over a two-year period' },
            { value: '57%', description: 'of IT budgets are spent on maintenance rather than innovation with technologies like cloud and AI' },
            { value: '2-3x', description: 'faster time to market for new products and services with cloud' },
            { value: '$10B+', description: 'in potential profit impact for a Fortune 500 company moving to cloud' },
        ];

        return <StatsGrid title="Cloud now" stats={stats} />;
    };

    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Cloud Data and AI',
                description: 'Data is the driver and AI is the differentiator',
                hasButton: true,
            },
            {
                title: 'Application Transformation',
                description: 'Become a world-class software organization',
                hasButton: true,
            },
            {
                title: 'Edge Computing',
                description: 'Reimagining experiences for people, purpose and profitability',
                hasButton: true,
            },
            {
                value: '57%',
                subDescription: "Traditional infrastructure can't keep up with the pace of change. Embrace a modern infrastructure with the complete 'run-to' end-to-end services capabilities to unlock opportunities of today and tomorrow.",
                title: 'Cloud Infrastructure',
                description: 'Gain resilience and innovate faster with a modern infrastructure',
                hasButton: true,
            },
            {
                title: 'Application Managed Services',
                description: 'Run to new: Outpace change with modern application management',
                hasButton: true,
            },
            // New cards from the image
            {
                title: 'Network',
                description: 'Build, optimize and monetize secure software-defined networks',
                hasButton: true,
            },
            {
                value: '82%',
                subDescription: 'of all companies that are fully achieving their cloud outcomes are using managed services to a moderate or great degree',
                title: 'Infrastructure Managed Services',
                description: 'Manage complexity to run better on cloud',
                hasButton: true,
            },
            {
                title: 'Cloud Strategy and Design',
                description: 'Capture better business value with cloud',
                hasButton: true,
            },
            {
                title: 'Sustainable technology',
                description: 'Engineer a better future',
                hasButton: true,
            },
            {
                title: 'Cloud security',
                description: 'Operate with confidence in a secure IT environment',
                hasButton: true,
            },
        ];




        return <ReinventGrid title="Reinvent with cloud" cards={cards} />;
    };
    const slides = [
        {
            title: "Reinventing with a digital core",
            image: Cloud_img1,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "Reinventing with a digital core",
            image: Cloud_img2,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "Reinventing with a digital core",
            image: Cloud_img3,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];


    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending with cloud?" slides={slides} />;
    };


    const cards = [
        { id: 1, logo: Cloud_partner_img1, content: 'ServiceNow content here' },
        { id: 2, logo: Cloud_partner_img2, content: 'Bringing together 65,000 dedicated professionals, the DXC and Microsoft alliance delivers comprehensive services to help organizations transform and thrive' },
        { id: 3, logo: Cloud_partner_img4, content: 'Our unflinching mission is to combine extraordinary new technologies with human ingenuity to meet business challenges and drive growth.' },
        { id: 4, logo: Cloud_partner_img3, content: 'The DXC-COM Google Practice is turning your cloud into an enterprise solution and making your business applications run like a cloud.' },
        { id: 5, logo: Cloud_partner_img1, content: 'Another ServiceNow content here' },
    ];


    const ScrollableCards = () => {
        const [activeIndex, setActiveIndex] = useState(2);

        const handlePrev = () => {
            setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cards.length - 1));
        };

        const handleNext = () => {
            setActiveIndex((prevIndex) => (prevIndex < cards.length - 1 ? prevIndex + 1 : 0));
        };

        return (
            <div className="flex flex-col items-center justify-center p-4 overflow-hidden">
                <h1 className="text-white text-2xl sm:text-3xl font-bold md:mb-12 text-center">Partners in change</h1>
                <div className="relative w-full max-w-5xl">
                    <div className="flex justify-center items-center h-64 sm:h-80 md:h-96 overflow-visible">
                        <AnimatePresence initial={false}>
                            {cards.map((card, index) => {
                                const isActive = index === activeIndex;
                                const offset = (index - activeIndex) * (window.innerWidth < 640 ? 200 : 300); // Adjust offset for mobile
                                const zIndex = cards.length - Math.abs(activeIndex - index);
                                return (
                                    <motion.div
                                        key={card.id}
                                        className={`absolute bg-[#14192E] rounded-lg shadow-lg pt-4 sm:pt-8 ${isActive ? 'w-4/5 sm:w-96 h-40 sm:h-48 md:h-64' : 'w-2/3 sm:w-80 h-32 sm:h-40 md:h-56'
                                            }`}
                                        initial={{ opacity: 0, scale: 0.8 }}
                                        animate={{
                                            opacity: isActive ? 1 : 0.6,
                                            scale: isActive ? 1 : 0.8,
                                            x: offset,
                                            zIndex: zIndex,
                                        }}
                                        exit={{ opacity: 0, scale: 0.8 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <div className="p-2 sm:p-4 md:p-6 h-full flex flex-col">
                                            <img src={card.logo} alt={`${card.logo.split('_')[0]} logo`} className="h-8 sm:h-12 object-contain mb-2 sm:mb-4 mx-auto" />
                                            <p className="text-gray-300 text-xs sm:text-sm flex-grow overflow-hidden text-center">{card.content}</p>
                                        </div>
                                    </motion.div>
                                );
                            })}
                        </AnimatePresence>
                    </div>
                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-4 mt-4 sm:mt-8">
                        <motion.button
                            onClick={handlePrev}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Previous card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronLeft size={20} />
                        </motion.button>
                        <motion.button
                            onClick={handleNext}
                            className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 sm:p-2 transition-colors duration-200"
                            aria-label="Next card"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <ChevronRight size={20} />
                        </motion.button>
                    </div>
                </div>
            </div>
        );
    };

    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: Cloud_awards_img1,
                alt: "Multicloud Managed Services"
            },
            {
                title: "Leader for Worldwide Cloud Professional Services - IDC",
                image: Cloud_awards_img2,
                alt: "Worldwide Cloud Professional Services"
            },
            {
                title: "Leader in data center outsourcing & hybrid",
                image: Cloud_awards_img3,
                alt: "Data Center Outsourcing & Hybrid"
            },
            {
                title: "Accenture named a Leader in Everest Group Cloud Services PEAK Matrix®",
                image: Cloud_awards_img4,
                alt: "Everest Group Cloud Services"
            }
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            // { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            // { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            // { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };



    return (
        <div className='bg-bg text-white '>
            <Header
                title="Take the world by cloud"
                description="Every day, cloud computing becomes more integral in driving reinvention and meeting the ever-changing needs of people around the world."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudNowStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithCloud />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ScrollableCards />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>

    )
}

export default CloudServices