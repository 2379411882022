import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/DataAndArtificialIntelligence_cover.png'
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import dataAi_img1 from '../../assets/Capabilities/dataAi_img1.png'
import dataAi_img2 from '../../assets/Capabilities/dataAi_img2.png'
import dataAi_img3 from '../../assets/Capabilities/dataAi_img3.png'
import dataAi_img4 from '../../assets/Capabilities/dataAi_img4.png'
import dataAi_img5 from '../../assets/Capabilities/dataAi_img5.png'
import dataAi_img6 from '../../assets/Capabilities/dataAi_img6.png'
import dataAi_img7 from '../../assets/Capabilities/dataAi_img7.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { motion, AnimatePresence } from 'framer-motion';
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';
import { LeaderGrid, RecognitionSection, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import microsoftLogo from '../../assets/Capabilities/Cloud_partner_img2.png'
import awsLogo from '../../assets/Capabilities/Cloud_partner_img3.png'
import googleLogo from '../../assets/Capabilities/Cloud_partner_img4.png'
import sapLogo from '../../assets/Capabilities/sap-logo.png'
import ibmLogo from '../../assets/Capabilities/ibm-logo.png'
import servicenowLogo from '../../assets/Capabilities/Cloud_partner_img1.png'
import databricksLogo from '../../assets/Capabilities/databricks-logo.png'
import oracleLogo from '../../assets/Capabilities/oracle-logo.png'

const PartnersSection = () => {
  const partners = [
    { name: 'Microsoft', logo: microsoftLogo },
    { name: 'AWS', logo: awsLogo },
    { name: 'Google', logo: googleLogo },
    { name: 'SAP', logo: sapLogo },
    { name: 'IBM', logo: ibmLogo },
    { name: 'ServiceNow', logo: servicenowLogo },
    { name: 'Databricks', logo: databricksLogo },
    { name: 'Oracle', logo: oracleLogo },
  ];

  return (
    <div className="bg-[#08082A] py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-white text-3xl font-bold text-center mb-10">Partners in change</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 sm:gap-6 md:gap-8 items-center justify-items-center">
          {partners.map((partner) => (
            <img
              key={partner.name}
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="h-6 sm:h-8 object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

function DataAndArtificialIntelligence() {
    const DataAIComponent = () => {
        return (
            <div className="bg-[#08082A] text-white p-4 sm:p-6 md:p-8 font-sans flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 mb-6 md:mb-0 md:pr-8 flex items-center justify-center">
                    <img
                        src={dataAi_img1}
                        alt="Person wearing VR headset"
                        className="w-full max-w-xs sm:max-w-sm md:max-w-full object-cover rounded-lg"
                    />
                </div>
                <div className="w-full md:w-2/3">
                    <h2 className="text-2xl font-bold mb-6 md:mb-12 lg:mb-16">Data and AI now</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                        <StatCard
                            value="7 in 10"
                            description="organizations have specific reskilling programs in place to address the impact of AI/ML on their workforce"
                        />
                        <StatCard
                            value="56%"
                            description="of respondents acknowledge data governance is the top challenge to adopt AI"
                        />
                        <StatCard
                            value="97%"
                            description="of organizations said generative AI will transform their company and industry"
                        />
                        <StatCard
                            value="67%"
                            description="of organizations plan to increase spending in technology and are prioritizing investments in data and AI"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const StatCard = ({ value, description }) => (
        <div className="bg-gradient-to-br from-[#21213B] to-[#08082A] rounded-lg p-6 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-16 h-16 bg-purple-600 opacity-20 rounded-br-full"></div>
            <div className="absolute bottom-0 right-0 w-16 h-16 bg-purple-600 opacity-20 rounded-tl-full"></div>
            <p className="text-3xl font-bold mb-2">{value}</p>
            <p className="text-sm">{description}</p>
        </div>
    );

    const ReinventWithDataAndAI = () => {
        const cards = [
            {
                title: 'Cloud foundation AI',
                description: 'Data is the driver and AI is the differentiator',
                hasButton: true,
            },
            {
                title: 'Responsible AI',
                description: 'Make responsible AI pervasive and systematic in the enterprise',
                hasButton: true,
            },
            {
                title: 'AI strategy and value',
                description: 'Where can you apply gen AI for the largest ROI ?',
                hasButton: true,
            },
            {
                title: 'Data Services',
                description: 'In the era of generative AI, your propriety data is your greatest differentiator',
                hasButton: true,
            },
            {
                title: 'Talent transformation and skilling',
                description: `Unlock your people's potential`,
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with data and AI" cards={cards} />;
    };
    const slides = [
        {
            title: "The Art of AI Maturity",
            image: dataAi_img2,
            gradient: "from-purple-600 to-pink-500"
        },
        {
            title: "A Collection of data & generative AI",
            image: dataAi_img3,
            gradient: "from-blue-600 to-cyan-500"
        },
        {
            title: "The New Data Economy",
            image: dataAi_img4,
            gradient: "from-indigo-600 to-purple-500"
        }
    ];


    const CloudTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending with cloud?" slides={slides} />;
    };


    const GlobalRecognition = () => {
        const awards = [
            {
                title: "Leader in Multicloud Managed Services - Forrester",
                image: dataAi_img5,
                alt: "Multicloud Managed Services"
            },
            {
                title: "Leader for Worldwide Cloud Professional Services - IDC",
                image: dataAi_img6,
                alt: "Worldwide Cloud Professional Services"
            },
            {
                title: "Leader in data center outsourcing & hybrid",
                image: dataAi_img7,
                alt: "Data Center Outsourcing & Hybrid"
            },
        ];

        return <RecognitionSection title="Awards and recognition" awards={awards} />;
    };
    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
            { name: "Johnson", title: "Lead - Data & AI", imageUrl: Cloud_leader_img1 },
            { name: "Shakriya", title: "Chief AI Officer", imageUrl: Cloud_leader_img1 },
            { name: "Chandran", title: "Chief Responsible AI Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };

    return (
        <div className='bg-bg text-white'>
            <Header
                title="Lead in the age of generative AI"
                description="In the last 30 years, no technology has promised to change everything across a business—until generative AI. Today, AI is the number one driver of business reinvention. And data readiness is one of the most important factors for AI success."
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <DataAIComponent />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithDataAndAI />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <CloudTrendingCarousel />
            </div>
            <div className='w-full mt-8 md:mt-16 lg:mt-20'>
                <PartnersSection />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <GlobalRecognition />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
            {/* Add more components and content here */}
        </div>
    )
}

export default DataAndArtificialIntelligence