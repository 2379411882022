import React from 'react'
import { Header } from '../../components/resuable/SapSolution'
import headerImage from '../../assets/Capabilities/TechnologyTransformation_cover.png'
import { LeaderGrid, ReinventGrid, StatsGrid, TrendingCarousel } from '../../components/resuable/CapabilitiesComponents';
import TechnologyTransformation_img1 from '../../assets/Capabilities/TechnologyTransformation_img1.png'
import TechnologyTransformation_img2 from '../../assets/Capabilities/TechnologyTransformation_img2.png'
import TechnologyTransformation_img3 from '../../assets/Capabilities/TechnologyTransformation_img3.png'
import Cloud_leader_img1 from '../../assets/Capabilities/Cloud_leader_img.png'
import { ContactContent } from '../../components/resuable/SapComponents';
import Footer from '../../components/Footer';

function TechnologyTransformation() {
    const TechTransformationStats = () => {
        const stats = [
            { value: '97%', description: 'of executives say their organization is undergoing a digital business transformation' },
            { value: '5x', description: 'Increase in the pace of digital transformation and adoption of digital technologies' },
            { value: '200%', description: 'Increase in organizations investing in emerging technologies (e.g. AI, blockchain, IoT)' },
        ];

        return <StatsGrid title="Tech transformation now" stats={stats} />;
    };


    const ReinventWithCloud = () => {
        const cards = [
            {
                title: 'Digital Core',
                description: 'Build a digital core that can fuel continuous business reinvention',
                hasButton: true,
            },
            {
                title: 'Tech Strategy',
                description: 'Get the flexibility and value you need from technology',
                hasButton: true,
            },
            {
                title: 'Technology Talent, Organization & Culture',
                description: 'Create a culture of change and upskill / reskill talent',
                hasButton: true,
            },
            {
                title: 'Modern Technology Roadmap',
                description: 'Evolve ways of working to better align customers and business',
                hasButton: true,
            },
        ];

        return <ReinventGrid title="Reinvent with technology transformation" cards={cards} />;
    };

    const slides = [
        {
            title: "More than hype—here's how to embrace the metaverse today",
            image: TechnologyTransformation_img1,
            gradient: "from-purple-400 to-purple-600"
        },
        {
            title: "How to build a responsible metaverse—starting now",
            image: TechnologyTransformation_img2,
            gradient: "from-blue-400 to-blue-600"
        },
        {
            title: "Climate change gets real in the metaverse",
            image: TechnologyTransformation_img3,
            gradient: "from-cyan-400 to-cyan-600"
        }
    ];

    const MetaverseTrendingCarousel = () => {
        return <TrendingCarousel title="What's trending?" slides={slides} />;
    };

    const LeadershipSection = () => {
        const leaders = [
            { name: "Andrew tye", title: "Group Chief Executive - Technology and Chief Technology Officer", imageUrl: Cloud_leader_img1 },
        ];
        return <LeaderGrid leaders={leaders} />;
    };
    return (
        <div className='bg-bg text-white'>
            <Header
                title="Transform your technology to deliver change faster"
                description="Reinvent your digital core with the right strategy, talent and modern practices to drive agility, increase ROI, and redirect spend to innovations that drive growth"
                backgroundImage={headerImage}
                isExploreBtn={false}
            />
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <TechTransformationStats />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ReinventWithCloud />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20'>
                <MetaverseTrendingCarousel />
            </div>
            <div className='w-full px-4 md:w-11/12 mx-auto mt-8 md:mt-16 lg:mt-20 mb-8 md:mb-16 lg:mb-20'>
                <LeadershipSection />
            </div>
            <div className='mx-auto mt-8 md:mt-16 lg:mt-20'>
                <ContactContent title={`Careers`} subtitle={`Grow your careers at the heart of change`} />
            </div>
            <Footer />
        </div>
    )
}

export default TechnologyTransformation
